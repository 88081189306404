import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldVila: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Vila?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_vila.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Vila?</h1>
          <h2>
            Not sure if pulling Vila is the right move? No worries! Check our
            review and guide for her!
          </h2>
          <p>
            Last updated: <strong>13/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="vila"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          The short answer is yes, you probably should pull for her. Vila is a
          very strong pure survival/support character who will benefit almost
          any team she’s in. She can buff damage, provide Moxie, give Eureka,
          and, of course, heal. She’s easy to use and will be a valuable
          character for a long time.
        </p>
        <SectionHeader title="Lore" />
        <p>
          Though often mistaken for a young woman with a peculiar skin ailment,
          those with even a little knowledge of arcanum can see that Vila is of
          Rusalka bloodline, a divergent branch of merfolk.
        </p>
        <p>
          The Rusalki traditionally distanced themselves from humans, only
          starting to intersect with human society around the mid-20th century.
          Vila is the crystallization of this intersection, a child of water
          with mostly human characteristics.
        </p>
        <p>
          She often returns to the waves, spending hour upon hour weaving a
          watery “fish tail” for herself.
        </p>
        <SectionHeader title="Review" />
        <p>
          Vila is a 6-star Arcanist of the Plant Afflatus. She’s an excellent
          all-around support who can heal, purify negative statuses, generate
          Moxie, and provide significant damage buffs through her critical
          support skills, making her one of the most versatile supports in the
          game. When you look at her skills, you’ll notice she is the first
          Arcanist we’ve received who has no attack incantation and instead
          focuses entirely on the support role.
        </p>
        <p>
          Starting with her ultimate, it provides a status that her Insight and
          other skills will reference. Her ultimate is a powerful mass buff that
          dispels all negative effects from allies, enhances preexisting effects
          generated by her other skills, and grants the crucial status [Rusalka]
          to Vila.
        </p>
        <p>
          [Rusalka] is a vital status that buffs Vila’s incantations and
          provides her Insights with extra abilities.
        </p>
        <p>
          Her first skill, “Beating Heart,” is a single-target buff that, while
          beneficial for everyone, is more geared toward Mental DMG support.
          This skill provides [Song of Generosity] which greatly increases
          Critical Rate and Critical DMG, with an additional focus on Mental DMG
          characters, it lasts for two rounds. It also provides [Power Burst], a
          simple 25% DMG boost. When she is in [Rusalka] status, this ability
          becomes even more powerful, as she will apply [Song of Passion]
          instead. This buffed version of the song provides extra DMG to the
          holder depending on the rank of the incantation they cast.
        </p>
        <p>
          Vila’s other skill, “Wordless Song,” is a simple single-target heal
          that also provides sturdiness and which again receives a buff if she
          is in [Rusalka] status. The buffed version additionally recovers HP to
          the ally with the lowest HP percentage (similar to Yenisei’s “Words of
          Water”).
        </p>
        <p>
          In line with her moveset, her Insights are also focused on support.
          Her Insight gives her Moxie when she enters battle, and the amount she
          gains is increased by one for every Mental DMG ally on her team, up to
          2. If she’s in [Rusalka] status at the start of any round, she’ll also
          gain a [Precast] Versatile incantation “Song of Inspiration”. It can
          be used without any AP cost, and grants Moxie +1 and Eureka +1 to one
          ally of choice. Her Insight III is another great passive and further
          demonstrates how AP-friendly she is. If she hasn’t cast anything,
          allies will simply receive free healing, and that value increases even
          more with [Rusalka].
        </p>
        <p>
          Vila is the first character in the game who doesn’t attack at all.
          While that might seem a downside, it works quite well for her and
          makes her a great match in AP-greedy setups. She is a great healer,
          similar to Tooth Fairy in some ways, except she’s more focused on
          single-target. Her active and passive healing are effective, she has a
          mass purification skill, and she is a great match for any mental or
          crit-based allies. She even provides Eureka. While being a
          jack-of-all-trades is typically not ideal in other cases, as it’s too
          spread out, Vila excels in everything she does, making her one of the
          best supports in the game so far.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    She can heal, provide CRIT support, generate Moxie and
                    Eureka. She is a highly versatile support character who can
                    enhance any team, especially with Mental DMG characters who
                    receive extra buffs. An easy fit in most teams.
                  </li>
                  <li>
                    She has passive healing, and her lack of attacking makes her
                    great when paired with AP-greedy allies.
                  </li>
                  <li>
                    With [Rusalka], she further boosts the effectiveness of her
                    support abilities.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    While not too much of a drawback, she doesn’t attack at all,
                    so if you need someone who can deal damage, she is not the
                    right choice.
                  </li>
                  <li>
                    Her skill buffs are all single-target, which may be limiting
                    if you require team-wide buffs.
                  </li>
                  <li>
                    Reliant on her Ultimate for fully making use of her kit.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>
          Vila works fine at P0. Since she is a 6-star character, pulling for
          extra copies of her is generally not recommended unless you plan to
          pull out the wallet.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          Vila is flexible as every team can benefit from a healer, but you
          should try to pair her with Mental DMG characters and those that rely
          on CRIT. Charlie, for example, is a great option for this. She deals
          high damage and meets the criteria to fully benefit from Vila.
          Additionally, Vila's focus on single targets is beneficial as it
          allows you to manage her health (this applies to any character with
          health-based abilities). In general, she’ll partner just fine with any
          Mental DMG characters. Marcus, Windsong, etc, etc. With how strong she
          is, she can also even work with weaker characters than you probably
          own like Matilda.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="charlie"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="marcus"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="mercuria"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="windsong"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Vila:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="dawn-is-coming"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="that-inquisitive-dear"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="her-second-life"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="take-the-hard-journey"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Resonance" />
        <h6>Level 7</h6>
        <StaticImage
          src="../../../images/reverse/generic/vila_r_1.webp"
          alt="Guides"
        />
        <h6>Level 9</h6>
        <StaticImage
          src="../../../images/reverse/generic/vila_r_2.webp"
          alt="Guides"
        />
        <h6>Level 10</h6>
        <StaticImage
          src="../../../images/reverse/generic/vila_r_3.webp"
          alt="Guides"
        />
        <br />
        <StaticImage
          src="../../../images/reverse/generic/vila_r_4.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <p>
          A few suggestions are listed below. 3-People teams are highlighted,
          with the 4th slot added at the side to provide a 4-P variation of the
          same team or even insights on possible variations of the 3-P team.
        </p>
        <h5>Standard Teams</h5>
        <div className="team-box ">
          <h5 className="star">
            We Plant, We Stan <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="marcus"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="avgust"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Druvis-III</strong>
              </li>
              <li>
                I just wanted to show you a team composition for a full Plant
                Afflatus setup. There are many options, but I picked some that
                work well together. You might consider adding someone not Plant
                like 6 to this team for additional debuffs, but overall this
                team should perform effectively. Vila supports everyone here
                with her Mental DMG abilities and healing. Marcus is a strong
                boss killer who deals significant damage through her abilities.
                With the other supports in this team, she’ll perform
                exceptionally well. Avgust provides additional damage buffs and
                support. If you need more options, you could also consider
                Druvis as an alternative damage dealer.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Charlie is in these lists kind of often ain’t she?
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="charlie"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="yenisei"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <p>
              Charlie may not be one of the top damage dealers anymore, but she
              fits very well in a team with Vila. First, she is quite squishy,
              but you need her to maintain a certain amount of health at all
              times. Vila can manage this with her single-target heal, which
              means you don’t need your heals to affect Charlie unless
              necessary. Vila's Mental DMG and crit support complement Charlie
              well. Yenisei is a good addition if you want Charlie to stay alive
              even longer, especially if you're finding her too squishy with
              just Vila. Alternatively, you can always add another support or
              sub DPS. There are almost infinite options for this, like 6,
              Regulus, and others.
            </p>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Free + Low Rarities Only<span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="matilda"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="sonetto"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <p>
              So, if you've pulled Vila but somehow only have free characters,
              she can still work for you. Free units aren't necessarily bad;
              however, in Matilda's case, she might be outclassed by other
              characters. Still, that doesn’t mean she’s unusable. Vila can
              support her just as she would in any other team. You should be
              able to progress well with this setup. Sonetto’s abilities are
              useful in most scenarios and also contribute to extra
              survivability. Although she doesn't fit perfectly as she is a
              Reality DMG character, she is still usable. Additionally, you have
              lower rarity units like Twins Sleep, which can provide extra Crit
              Support.
            </p>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldVila;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Vila? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Vila is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
